<template>
  <div id="changepassword">
    <div class="titleLine">
      <h1>Please Enter Your Password</h1>
      <h3>
        設定您賬戶的基本資料及其他基礎信息設定您賬戶的基本資料及其他基礎信息設定您賬戶的
        基本資料及其他基礎信息
      </h3>
    </div>
    <div class="content">
      <div class="contentItem" v-if="!isPwdOK">
        <div class="inputBox">
          <label class="title"><h2>Enter Your Old Password:</h2></label>
          <input type="password" placeholder="Enter Your Old Password" v-model="changePasswordform.oldPassword">
        </div>

      </div>
      <div class="contentItem" v-if="isPwdOK">
        <div class="inputBox">
          <label class="title"><h2>Enter Your New Password:</h2></label>
          <input type="password" placeholder="Enter Your New Password" >
        </div>
        <div class="inputBox">
          <label class="title"><h2>Enter Your New Password Again:</h2></label>
          <input type="password" placeholder="Enter Your New Password" v-model="changePasswordform.newPassword">
        </div>
      </div>
    </div>
    <btn-submit color="red" class="btn" @click="goNext">{{btnCentent}}</btn-submit>
  </div>
</template>

<script>
import { changePassword } from '@/axios/api/login'
import BtnSubmit from '@/components/button/BtnSubmit.vue'
export default {
  name:'ChangePassword',
  components: {
    BtnSubmit

  },
  data() {
    return {
      isPwdOK: false,
      btnCentent: "Next",
      count: 1,
      changePasswordform: {}
    }
  },
  methods:{
    goNext(){
      this.isPwdOK = !this.isPwdOK
      this.count++
      if(this.isPwdOK){
        this.btnCentent = "Save"
      }else{
        this.btnCentent = "Next"
      }
      if(this.count==3){
        changePassword(this.changePasswordform)
        this.$router.push("/usercenter")
      }
    }
  },
}
</script>

<style scoped>
.titleLine{
  color: aliceblue;
  height: 140px;
  width: 50%;
  padding-top: 40px;
}
h3{
  color: rgb(110, 110, 110);
}
h2{
  color: aliceblue;
}
.content{
  display: flex;
}
.contentItem{
  width: 400px;
}
input{
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid #fff;
  width: 340px;
  height: 40px;
  font-size: 18px;
  color: #fff;
}
.inputBox{
  margin-bottom: 20px;
}
.btn{
  margin-left: 40%;
  margin-top: 100px;
}
</style>
